/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main {
  padding: 100px 0 0 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  color: #000000;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
}

.container > img {
  width: 100%;
}

section {
  padding: 40px 0;
}

section h2 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.category {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.category img {
  width: 150px;
  margin-right: 20px;
  border-radius: 4px;
}

footer {
  background-color: #A40054;
  text-align: center;
  padding: 10px;
}

footer p {
  color: #ffffff;
}

p {
  margin: 10px 0;
}


header {
  background-color: #f2f2f2;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.logo h1 {
  margin: 0;
}

nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li a {
  text-decoration: none;
  color: #333;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 16px;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.center {
  display: flex;
  justify-content: center;

  margin: 0 0 100px 0;
}

.center > img {
  width: 500px;
}

.to-portfolio {
  margin: 50px 0 0 0;
  padding: 10px 20px;
  cursor: pointer;

  background-color: #6CADDF;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}

.to-portfolio:hover {
  background-color: #4F8ACF;
  transition: 200ms;
}

.to-portfolio:active {
  background-color: #1f3854;
}

.portfolio {
  padding: 100px;
}